import { defineStore } from "pinia";
import { IFilter } from "server/models/filter";
import { query } from "@/composables/store";

export const useFilters = defineStore("filters", () => {
	const mock = ref<boolean>(true);

	const filters = ref<IFilter[]>();

	const load = async function (): Promise<void> {
		if (filters.value?.length) return;
		const { list } = await query<IFilter>("filters");
		filters.value = list;
	};

	const companies = computed(() => {
		const list = filters.value?.filter((filter) => filter.category === "company");
		return list;
	});

	const contacts = computed(() => {
		const list = filters.value?.filter((filter) => {
			console.log(filter.category);
			return filter.category === "contact";
		});
		return list;
	});

	return {
		filters,
		companies,
		contacts,
		mock,
		load,
	};
});
